.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  display: flex;
  flex-direction: column;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (max-width: 56.5rem) {
  .col + .col {
    margin-top: 1rem;
  }
}

@media (min-width: 56.5rem) {
  .col-1 {
    flex: 0 0 8.3333337%;
    max-width: 8.3333337%;
  }

  .col-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-4 {
    flex: 0 0 33.3333337%;
    max-width: 33.3333337%;
  }

  .col-5 {
    flex: 0 0 41.666666667%;
    max-width: 41.666666667%;
  }

  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-7 {
    flex: 0 0 58.333333333%;
    max-width: 58.333333333%;
  }

  .col-8 {
    flex: 0 0 66.666666667%;
    max-width: 66.666666667%;
  }

  .col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-10 {
    flex: 0 0 83.333333333%;
    max-width: 83.333333333%;
  }

  .col-11 {
    flex: 0 0 91.666666667%;
    max-width: 91.666666667%;
  }

  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
