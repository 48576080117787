.radio {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1em;
  height: 1em;
  border: 0.15em solid theme('colors.neutral-200');
  border-radius: 50%;

  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  transition: all;
  transition-duration: 0.2s;
}

.radio::before {
  content: '';
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em theme('colors.primary');
}
.radio:disabled::before {
  box-shadow: inset 1em 1em theme('colors.neutral-200');
}

.radio:checked::before {
  transform: scale(1.1);
  border-color: theme('colors.primary');
}
.radio:checked::before:disabled {
  border-color: theme('colors.neutral-200');
}

.radio:not(:checked):hover {
  border-color: theme('colors.neutral-900');
}
.radio:not(:checked):hover:disabled {
  border-color: theme('colors.neutral-200');
}

.radio:hover:not(:disabled) {
  box-shadow: 0 0 0.95em theme('colors.primary');
}
.radio:not(:checked):hover:not(:disabled) {
  box-shadow: 0 0 0.95em theme('colors.red-100');
}

.radio:checked {
  border-color: theme('colors.primary');
}
.radio:checked:disabled {
  border-color: theme('colors.neutral-200');
}

.radio:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}

.radio:not(:checked):hover {
  box-shadow: 0 0 2.75em theme('colors.primary');
}
