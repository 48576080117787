.customLegend {
  transform: translate(4%, -40%);
  max-width: 165px;
  width: 100%;
  z-index: 0;
}

.customLegendValues {
  margin-top: 15px;
}

.legendItem {
  color: #696c80;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  position: relative;
}

.legendText:before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.legendItem:nth-child(1) .legendText:before {
  background: #53d2c5;
}
.legendItem:nth-child(2) .legendText:before {
  background: #665bff;
}
.legendItem:nth-child(3) .legendText:before {
  background: #ddf6f3;
}
.legendItem:nth-child(4) .legendText:before {
  background: #fdc132;
}
.legendItem:nth-child(5) .legendText:before {
  background: #1F77B4;
}
.legendItem:nth-child(6) .legendText:before {
  background: #FF7F0E;
}
.legendItem:nth-child(7) .legendText:before {
  background: #2CA02C;
}
.legendItem:nth-child(8) .legendText:before {
  background: #D62728;
}

.legendItem:first-child {
  margin-top: 0;
}

.legendPercent {
  font-weight: bold;
  color: #3f404f;
}
