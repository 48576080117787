.menuItem {
  cursor: pointer;
}

.menuItem > ul {
  height: 0;
  opacity: 0;
  transition: all 0.3s ease-in;
  text-indent: 10px;
  overflow: hidden;
}

.menuItem > ul > div {
  padding: 5px 0;
}

.menuItem:hover > ul {
  height: 100%;
  opacity: 1;
  padding: 5px 0;
}

.activeSubmenu {
  height: 100% !important;
  opacity: 1 !important;
  padding: 5px 0;
}
