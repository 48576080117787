.popup {
  width: max-content;
  position: absolute;
  z-index: 1;
}

.popup.topStart {
  bottom: calc(100% + 0.75rem);
  left: 0;
}

.popup.topEnd {
  bottom: calc(100% + 0.75rem);
  right: 0;
}

.popup.bottomStart {
  top: calc(100% + 0.75rem);
  left: 0;
}

.popup.bottomEnd {
  top: calc(100% + 0.75rem);
  right: 0;
}
