.h1 {
  @apply text-neutral-900 font-bold text-2xl mb-4;
}
h1.large{
  @apply text-neutral-900 font-semibold text-4xl;
}

h2.large{
  @apply text-neutral-900 font-semibold text-3xl;
}

p.large{
  @apply text-neutral-900 font-semibold text-lg;
}

p.underline{
  @apply text-neutral-900 font-medium text-lg;
  text-decoration: underline;
}
