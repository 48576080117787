.heatmapContainer {
  position: relative;
}

.heatmapBgImage {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
