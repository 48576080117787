.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  min-width: 15px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #53d2c5;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 1s;
  font-size: 0.8rem;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  border-width: 5px;
  border-style: solid;
}

.top {
  bottom: 130%;
  left: 50%;
  transform: translateX(-50%);
  margin-left: -7px;
}

.bottom {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-left: -7px;
}
.left {
  top: -5px;
  right: 140%;
}
.right {
  top: -5px;
  right: 110%;
}

.top::after {
  top: 100%;
  left: 50%;
  border-color: #53d2c5 transparent transparent transparent;
}
.bottom::after {
  bottom: 100%;
  left: 50%;
  border-color: transparent transparent #53d2c5 transparent;
}
.right::after {
  right: 100%;
  border-color: transparent #53d2c5 transparent transparent;
}

.left::after {
  top: 50%;
  left: 100%;
  border-color: transparent transparent transparent #53d2c5;
  margin-top: -5px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
