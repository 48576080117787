.track-in-icon {
  display: inline-flex;
}

.track-in-icon.--size-sm {
  font-size: 1rem;
}

.track-in-icon.--size-md {
  font-size: 1.5rem;
}

.track-in-icon.--size-lg {
  font-size: 2rem;
}

.track-in-icon.--size-xl {
  font-size: 3rem;
}

.track-in-icon svg {
  width: 1em;
  height: 1em;
  fill: currentColor;
}
