@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './_typography.css';
@import './icon-styles.global.css';
@import './_datepicker.css';
@import './_plotly.css';
body,
#root {
  height: 100vh;
}

.ReactModal__Content.ReactModal__Content--after-open {
  overflow: unset !important;
}

.ReactModal__Overlay {
  z-index: 10;
}

.rmsc .dropdown-heading {
  height: auto !important;
  min-height: 38px;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.custom-heatmap .nsewdrag.drag,
.plotly .nsewdrag.drag,
.plotly .nsdrag.drag,
.plotly .ndrag.drag,
.plotly rect {
  cursor: auto !important;
}

.plotly .main-svg .hoverlayer {
  z-index: 10;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
