.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  padding: 40px 20px;
  max-width: 450px;
}

.buttonBack {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 1.3rem;
  color: #666;
}
