.checkbox {
  -webkit-appearance: none;
  appearance: none;
  z-index: 1;

  background-color: theme('colors.primary');
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1em;
  height: 1em;
  border: 1px solid theme('colors.neutral-200');
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;

  transition: all;
  transition-duration: 0.2s;
}

.checkbox:not(:checked):hover {
  border-color: theme('colors.neutral-900');
}

.checkbox:hover {
  box-shadow: 0 0 0.75em theme('colors.primary');
}

.checkbox:checked::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  transform: scale(1);
  transition: 120ms transform ease-in-out;

  box-shadow: inset 1em 1em theme('colors.white');

  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.checkbox:checked {
  border-color: theme('colors.primary');
}

.checkbox:not(:checked) {
  background-color: theme('colors.white');
}

.checkbox:disabled {
  background-color: theme('colors.neutral-400');
}

.checkbox:disabled:not(:checked) {
  background-color: theme('colors.neutral-50');
  border-color: theme('colors.neutral-200');
}

.checkbox:disabled:checked {
  border: none;
}

.checkbox:hover:disabled {
  box-shadow: none;
  border-color: none;
}
