.showPassword {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2rem;
}

.remember {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
